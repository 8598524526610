function productQuestionUi($root, results = []) {
  const logFactory = GlobalSite.checkDependency('GlobalSite.logFactory');
  const inbentaApi = GlobalSite.checkDependency('GlobalSite.inbentaApi');
  const Log = logFactory('answerBlockHandler.js');
  const isTracking = inbentaApi.isTracking();

  async function template() {
    const promises = results.map(async (question, index) => {
      let ctaEC = null;
      const haveCrossSellingElem = !!Number(question?.attributes?.CTA_EC);
      if (haveCrossSellingElem) {
        ctaEC = await inbentaApi.getCrossSelling(2);
      }
      return `<li class="accordion-item" data-accordion-item data-index=${index}>
                            <a href="#" class="accordion-title" role="button"
                            data-source="faq"
                            data-tracking-type="click" 
                            data-tracking-value="${question?.tracking?.clickCode || ''}" 
                            >${question?.title}</a>
                            <div class="accordion-content" data-tab-content>
                                ${question?.attributes?.ANSWER_TEXT}
                                ${haveCrossSellingElem ? ctaEC?.results[0]?.attributes?.html : ''}
                                ${
                                  isTracking
                                    ? `
                                <div class="inbenta-question-rating" id=${index}>
                                    <div class="inbenta-question-rating-content">
                                        <div class="inbenta-question-rating--first-step">
                                            <span>Ces informations vous ont-elles aidé ?</span>
                                            <div class="inbenta-question-rating-btn">
                                                <div class="inbenta-question-rating-btn-item yes">
                                                    <button data-source="faq" data-tracking-type="rate" data-tracking-value="${
                                                      question?.tracking?.rateCode || ''
                                                    }" data-value="1" title="Oui, ces informations vous ont aidé" type="submit" value="Oui">Oui</button>
                                                </div>
                                                <div class="inbenta-question-rating-btn-item no">
                                                    <button data-source="faq" data-tracking-type="rate" data-tracking-value="${
                                                      question?.tracking?.rateCode || ''
                                                    }" data-value="2" title="Non, ces informations ne vous ont pas aidé" type="submit" value="Non">Non</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="inbenta-question-rating--second-step">
                                            <label for="textarea-inbenta">Veuillez nous indiquer pourquoi ces informations ne vous ont pas aidé. Nous ferons le nécessaire pour les améliorer.</label>
                                            <textarea placeholder="Expliquez-nous ici." id="textarea-inbenta"></textarea>
                                            <div class="text-right">
                                                <button data-source="faq" data-tracking-type="rate" data-tracking-value="${
                                                  question?.tracking?.rateCode || ''
                                                }" data-value="2" type="submit" class="button" value="Envoyer" disabled="true">Envoyer</button>
                                            </div>
                                        </div>
                                        <div class="inbenta-question-rating--final-step">
                                            <span>Merci !</span>
                                        </div>
                                    </div>
                                </div>                                
                                `
                                    : ''
                                }


                            </div>

                        </li>
            `;
    });
    const tmp = await Promise.all(promises);
    return tmp.join('');
  }

  function intiEvent($root) {
    const $accordionsLi = $root.find('li');

    let activeItemIndex;
    let $textarea = $(`.inbenta-question-rating textarea`);
    let $firstStep = $(`.inbenta-question-rating--first-step`);
    let $secondStep = $(`.inbenta-question-rating--second-step`);
    let $finalStep = $(`.inbenta-question-rating--final-step`);
    let buttonEvents = {};
    $secondStep.hide();
    $finalStep.hide();

    $textarea.on('keyup', (e) => {
      if (e.target.value.length === 0) {
        $textarea.parent().find('button').prop('disabled', true);
      } else if (e.target.value.length >= 1) {
        $textarea.parent().find('button').prop('disabled', false);
      }
    });

    $root.on('down.zf.accordion', (e) => {
      const $this = $(e.target).find('li.accordion-item.is-active');
      const $link = $this.find('a');
      const source = $link.data('source');
      const trackingType = $link.data('trackingType');
      const trackingValue = $link.data('trackingValue');
      activeItemIndex = $this.data('index');
      $textarea = $(`#${activeItemIndex} textarea`);
      $firstStep = $(`#${activeItemIndex} .inbenta-question-rating--first-step`);
      $secondStep = $(`#${activeItemIndex} .inbenta-question-rating--second-step`);
      $finalStep = $(`#${activeItemIndex} .inbenta-question-rating--final-step`);

      buttonEvents = {
        Oui: {
          hide: $firstStep,
          show: $finalStep,
        },
        Non: {
          hide: $firstStep,
          show: $secondStep,
        },
        Envoyer: {
          hide: $secondStep,
          show: $finalStep,
        },
      };
      if (!source || !trackingType || !trackingValue || !isTracking) {
        return;
      }
      inbentaApi.sendTrackingEvents({ type: trackingType, data: { code: trackingValue } }, source);
      Log.log({
        from: 'accordion foundation',
        name: $link[0]?.localName,
        trackingType,
        trackingValue,
        source,
      });
    });

    $accordionsLi.on('click', (e) => {
      const $this = $(e.target);

      const source = $this.data('source');
      const trackingType = $this.data('trackingType');
      const trackingValue = $this.data('trackingValue');
      if (!source || !trackingType || !trackingValue || !isTracking) {
        return;
      }

      if ($this[0]?.localName === 'button') {
        buttonEvents[$this[0].value].hide.hide();
        buttonEvents[$this[0].value].show.show();
        inbentaApi.sendTrackingEvents(
          {
            type: trackingType,
            data: { code: trackingValue, value: $this.data('value'), comment: $textarea.val() },
          },
          source,
        );
      }

      Log.log({ from: 'click', name: $this[0]?.localName, trackingType, trackingValue, source });
    });
  }

  (async function render() {
    $root.html(await template());
    const $foundationAccordion = new Foundation.Accordion($root);
    Log.log($foundationAccordion);

    intiEvent($root);
  })();
}

(function productQuestionHandler() {
  window.GlobalSite = window.GlobalSite || {};

  const exportToGlobalSite = GlobalSite.checkDependency('GlobalSite.exportToGlobalSite');

  exportToGlobalSite({
    productQuestionUi,
  });
})();
